import debugCreator from 'debug';
import React, { ChangeEvent, Fragment } from 'react';

import { checkIfFitsPattern } from 'shared/utils';
import { colors, fontSize as fontSizes } from 'styles/cp';

const debug = debugCreator('BaseInput');

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  pattern?: string;
  fontSize?: string;
  theme?: 'qbo';
  isInError?: boolean;
  ariaLabel?: string;
}

const BaseInput: React.FC<Props> = ({
  children,
  onChange,
  fontSize,
  theme,
  isInError,
  ariaLabel,
  ...rest
}) => {
  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!checkIfFitsPattern({ pattern: rest.pattern, value: e.target.value, debug })) {
      return e.preventDefault();
    }

    typeof onChange === 'function' && onChange(e);
  };

  return (
    <Fragment>
      <input onChange={_onChange} {...rest} aria-label={ariaLabel} />
      {children}

      <style jsx>
        {`
          input {
            height: 100%;
            border: none;
            border-radius: 4px;
            padding-left: 8px;
            margin: 0;
            width: 100%;
            font-size: ${fontSize || fontSizes.xs};
            background-color: ${isInError && theme === 'qbo' ? 'rgba(255, 0, 0, 0.09)' : ''};
            font-family: AvenirNextforINTUIT-Regular;
            outline: none;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }

          input::placeholder {
            color: ${theme === 'qbo' ? colors.gray01 : colors.gray05};
          }
        `}
      </style>
    </Fragment>
  );
};

export default BaseInput;
