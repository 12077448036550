import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import RoundedModal from 'components/Core/Modal/RoundedModal/RoundedModal';
import Button from 'components/Shared/Button/Button';
import Hr from 'components/Shared/Hr/Hr';
import Spinner from 'components/Shared/Spinner/Spinner';
import { colors, fontSize, breakpoints } from 'styles/cp';

export interface Props {
  hide: () => void;
  onContinue: () => void;
  headerIntl?: any;
  cancelIntl?: any;
  continueIntl: any;
  hideOnContinueFinish?: boolean;
  continueDisabled?: boolean;
  bodyContainerWidth?: string;
  removeDefaultBackground?: boolean;
  withHr?: boolean;
  customDesignKey?: string;
  withHeader?: boolean;
  withCancelBtn?: boolean;
  marginTop?: string;
}

const ActionModal: React.FC<Props> = ({
  hide,
  onContinue,
  headerIntl,
  cancelIntl = { id: 'CANCEL', defaultMessage: 'cancel' },
  continueIntl,
  children,
  hideOnContinueFinish = true,
  continueDisabled,
  bodyContainerWidth,
  removeDefaultBackground,
  withHr = true,
  customDesignKey,
  withHeader = true,
  withCancelBtn = true,
  marginTop = '72px',
}) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const isFreeTextModal = customDesignKey === 'freeTextModal';

  const onContinueClick = async () => {
    setIsInProgress(true);
    await onContinue();
    if (hideOnContinueFinish) {
      hide();
    }
  };

  const onHideClick = async () => {
    if (!isInProgress) {
      hide();
    }
  };

  const header = withHeader ? (
    <FormattedMessage
      id={headerIntl?.id}
      defaultMessage={headerIntl?.defaultMessage}
      values={headerIntl?.values}
    />
  ) : undefined;

  return (
    <Fragment>
      <RoundedModal
        hide={() => onHideClick()}
        header={header}
        removeDefaultBackground={removeDefaultBackground}
        customDesignKey={'freeTextModal'}
      >
        <div className="body-container">
          <div className="modal-body">{children}</div>
          {withHr && <Hr marginTop={0} marginBottom={0} borderColor={colors.gray04} />}
          <div className="cta">
            {withCancelBtn && (
              <span
                data-cy="action-modal-btn-cancel"
                className="back-btn"
                onClick={() => onHideClick()}
              >
                <FormattedMessage id={cancelIntl?.id} defaultMessage={cancelIntl?.defaultMessage} />
              </span>
            )}
            <Button
              disabled={continueDisabled}
              style={{ width: isFreeTextModal && '110px' }}
              data-cy="action-modal-btn-continue"
              buttonType="dark"
              onClick={async () => await onContinueClick()}
              aria-label={continueIntl.defaultMessage}
              width={withCancelBtn ? '208px' : '100px'}
              backgroundColor={colors.green}
            >
              {isInProgress ? (
                <Spinner
                  color={colors.green}
                  label={''}
                  bgStrokeWidth={2.5}
                  width={25}
                  strokeWidth={5}
                  bgColor={colors.gray08}
                />
              ) : (
                <FormattedMessage
                  id={continueIntl.id}
                  defaultMessage={continueIntl.defaultMessage}
                />
              )}
            </Button>
          </div>
        </div>
      </RoundedModal>
      {/* language=scss */}
      <style jsx>{`
        .body-container {
          width: ${bodyContainerWidth || '496px'};
          @media screen and (max-width: ${breakpoints.sm}) {
            width: 320px;
          }

          .modal-body {
            text-align: left;
            padding-top: 16px;
            margin-bottom: 32px;

            /* @growth_exp - freetext */
            ${isFreeTextModal &&
            `@media screen and (max-width: ${breakpoints.sm}) {
             
             }`}
          }

          .cta {
            margin-top: ${marginTop};
            display: flex;
            justify-content: ${withCancelBtn ? 'space-between' : 'center'};
            /* @growth_exp - freetext */
            @media screen and (max-width: ${breakpoints.sm}) {
              margin-top: 48px;
              ${isFreeTextModal
                ? `flex-direction: row;
                margin-top: 10px;
              justify-items: center;
              border-top: unset;
              `
                : `flex-direction: column-reverse;
              align-items: center;`}
            }

            .back-btn {
              line-height: 36px;
              font-weight: ${isFreeTextModal && '700'};
              font-size: ${isFreeTextModal ? fontSize.sm : fontSize.xs};
              margin-left: ${isFreeTextModal && '20px'};
              color: ${colors.intuit_blue};
              cursor: pointer;
              @media screen and (max-width: ${breakpoints.sm}) {
                /* @growth_exp - freetext */
                ${isFreeTextModal
                  ? `text-align: center;
                width: 60%;
                margin-left: 0px;`
                  : `margin-top: 28px;
                line-height: 16px;`}
              }
            }

            .hide {
              display: none;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default ActionModal;
